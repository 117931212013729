<template>
  <nav
    class="header z-ind"
  >
  <div class="container mx-auto d-flex">
    <router-link
      class="logo-title"
      to="/"
    > ファイナンシャル・プランニング技能検定２級 </router-link>

  </div>

  </nav>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

import StorageService from "../services/storage";

@Component
export default class HeaderAuth extends Vue {

  created() {

  }
  mounted() { }
}
</script>
<style>
nav.header {
  background-color: #01b2a0;
  height: 50px;
  filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.2));
  margin-bottom: 5em;
}

.logo-title {
  font-weight: bold;
  color: White;
  line-height: 50px;
  text-decoration: none;
}
.logo-title:hover {
  color: White;
  opacity: 0.7;
}
.z-ind {
  position: relative;
  z-index: 9999 !important;
}
.font-white {
  color: white;
  height: 50px;
  line-height: 50px;
  font-size: 1.3em;
}
</style>