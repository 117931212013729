<template>
  <div class="header">
    <div>
      <div class="d-flex  align-items-center" style="height:90px">
        <div class="flex-fill">
          <button class="titleBtn btn btn-link" @click="tapLogo">ファイナンシャル・プランニング技能検定２級</button>
        </div>
        <div>
          <Timer @end="endForce" />
          <div class="text-center">
            <button class="btn btn-primary end-button" @click="end">試験終了</button>
          </div>
        </div>
      </div>
      <div class="text-center" style="margin-top: -2em">{{title}} <br> {{ $store.state.data.length}}問中{{ $store.state.index + 1 }}問目</div>
    </div>
    <hr class="m-0" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

import StorageService from "../services/storage";
import BasicUtil from "../services/basicUtil";
import Timer from "./Timer.vue";
import Shiken from "@/models/shiken";
import HttpClient from "@/services/httpClient";

@Component({
  components: { Timer }
})
export default class HeaderExam extends Vue {
  private title: string = ""
  created() {
    const questions = this.$store.state.data;
    if (questions.length <= 0) return new BasicUtil().backtoTop();
    const shiken: Shiken | null = new HttpClient().getShiken(
      questions[0].shikenId
    );
    if (shiken == null) return new BasicUtil().backtoTop();
    this.title = shiken.title
  }
  mounted() {
    
  }

  tapLogo() {
    if (this.$store.state.data.length == 0) new BasicUtil().reload();
    new BasicUtil().reload();
  }

  private end() {
    if (!confirm("終了しますか？")) return;
    // if (this.$store.state.isRe) {
    //   this.$router.push(`/`);
    // } else {
      this.$router.push(`/end`);
    // }
    
  }

  private endForce() {
    alert("制限時間が終了しました")
    this.$router.push(`/end`);
  }
}
</script>
<style scoped>
.header {
  height: 120px;
}

.header>div {
  height: 119px;
}

.header .titleBtn {
  text-decoration: none;
  font-weight: bold;
  color: #212529;
}

.end-button {
  width: 120px;
}
</style>