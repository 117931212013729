<template>
    <div class="header">
    <button class="titleBtn btn btn-link"
      @click="tapLogo"
    >ファイナンシャル・プランニング技能検定２級</button>

    <hr class="m-0"/>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

import StorageService from "../services/storage";

@Component
export default class Header extends Vue {

  created() {
  }
  mounted() {}

  tapLogo() {
    return location.href = "/";
  }
}
</script>
<style scoped>
.header {
  height: 70px;
}

.header a {
  line-height: 70px;
  text-decoration: none;
  font-weight: bold;
  color: #212529;
}
.header .titleBtn {
  line-height: 70px;
  text-decoration: none;
  font-weight: bold;
  color: #212529;
}

</style>