<template>
  <div>
    <div v-if="!isAllAuth2" class="question-wrap" style="max-width:765px; margin: 0 auto">

      <h2 class="bB my-4 fzXl" style="font-size: 22px">ＦＰ ２級 CBT模試一覧</h2>
      <div class="mb-3" style="">
        <p>ＣＢＴ試験体験プログラムは以下書籍購入者特典となります。</p>

        <div class=" bB " style="font-size: 16px">
          <select class="form-control" v-model="currentBook" @change="selectedBook">
            <option value="0" disabled default>書籍を選択してください</option>
            <option v-for="b in books" :value="b.val" v-if="b.val != 1 || !isAllAuth">{{ b.name }}</option>
          </select>
        </div>
      </div>
      <div>
        <div v-if="qas.q != ''">
          <p class="tBlu my-2" style="font-size: 16px">
            ご購入様確認用の質問にご回答の上、ＣＢＴ模試をご利用ください。
          </p>

          <div>
            <div class="mt-2">
              質問：
              <p class="questionBb">{{ qas.q }}</p>
            </div>
            <div class="my-3">
              <input type="text" class="form-control mainFont" style="font-size: 16px" v-model="answer"
                placeholder="答え" />
            </div>
            <div class="text-center">
              <button class="btn bdr10 bgGrn sdwGrn2 px-4 py-2 tWht fzM text-left mx-4 mb-3 mainFont"
                style="font-size: 18px" @click="answerQa">認証</button>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="question-wrap" style="max-width:765px; margin: 2em auto">
      <h3 class="bB mt-2 tGrn3">CBT体験版</h3>
      <ul class="list-group list-group-flush">
        <li class="list-group-item pt-2 pb-2" v-for="s in shikens">
          <div class="q-list" v-if="s.isAuth">
            <span class="q-list-text">{{ s.name }}</span>
            <span><a class="btn bdr10 bgGrn sdwGrn2 px-4 py-1 tWht fzM text-left mainFont" style="font-size: 18px"
                :href="'/start/' + String(s.val)">受検</a></span>
          </div>
          <div class="q-list" v-else>
            <span class="q-list-text disa">{{ s.name }}</span>
            <span><span class=" bdr10 bgGry sdwGry px-4 py-1 tWht fzM text-left mainFont"
                style="font-size: 18px">受検</span></span>
          </div>
        </li>

      </ul>
      <!-- <h3 class="bB mt-3 tGrn3">第１回目 予想模試</h3>
      <ul class="list-group list-group-flush">
        <li class="list-group-item pt-2 pb-2" v-for="s in shikens1">
          <div class="q-list" v-if="s.isAuth">
            <span class="q-list-text">{{ s.name }}</span>
            <span><a class="btn bdr10 bgGrn sdwGrn2 px-4 py-1 tWht fzM text-left mainFont" style="font-size: 18px"
                :href="'/start/' + String(s.val)">受検</a></span>
          </div>
          <div class="q-list" v-else>
            <span class="q-list-text disa">{{ s.name }}</span>
            <span><span class=" bdr10 bgGry sdwGry px-4 py-1 tWht fzM text-left mainFont"
                style="font-size: 18px">受検</span></span>
          </div>
        </li>

      </ul>
      <h3 class="bB mt-3 tGrn3">第２回目 予想模試</h3>
      <ul class="list-group list-group-flush">
        <li class="list-group-item pt-2 pb-2" v-for="s in shikens2">
          <div class="q-list" v-if="s.isAuth">
            <span class="q-list-text">{{ s.name }}</span>
            <span><a class="btn bdr10 bgGrn sdwGrn2 px-4 py-1 tWht fzM text-left mainFont" style="font-size: 18px"
                :href="'/start/' + String(s.val)">受検</a></span>
          </div>
          <div class="q-list" v-else>
            <span class="q-list-text disa">{{ s.name }}</span>
            <span><span class=" bdr10 bgGry sdwGry px-4 py-1 tWht fzM text-left mainFont"
                style="font-size: 18px">受検</span></span>
          </div>
        </li>

      </ul>
      <h3 class="bB mt-3 tGrn3">第３回目 予想模試</h3>
      <ul class="list-group list-group-flush">
        <li class="list-group-item pt-2 pb-2" v-for="s in shikens3">
          <div class="q-list" v-if="s.isAuth">
            <span class="q-list-text">{{ s.name }}</span>
            <span><a class="btn bdr10 bgGrn sdwGrn2 px-4 py-1 tWht fzM text-left mainFont" style="font-size: 18px"
                :href="'/start/' + String(s.val)">受検</a></span>
          </div>
          <div class="q-list" v-else>
            <span class="q-list-text disa">{{ s.name }}</span>
            <span><span class=" bdr10 bgGry sdwGry px-4 py-1 tWht fzM text-left mainFont"
                style="font-size: 18px">受検</span></span>
          </div>
        </li>

      </ul> -->
    </div>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Emit } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BasicUtil from "../services/basicUtil";
@Component({
  components: { Header, Footer }
})
export default class AuthView extends Vue {
  private qas: any = { q: "", a: "" };
  private answer: string = "";
  private grade: string = this.$route.params.grade ?? "3";
  private gradeStr: string = "";
  private isCheck: boolean = false;
  private isError: boolean = false;
  private currentBook: number = 0
  private currentShikenIds: number[] = []
  private isAllAuth: boolean = false
  private isAllAuth2: boolean = false
  private books: any[] = [
    { name: "スゴい!だけじゃない!! FP２級 テキスト＆問題集 2024-2025年版", val: 1 },
    { name: "スゴい!だけじゃない!! FP２級 徹底分析!予想模試 2024-2025年版", val: 2 },
  ]

  private shikens: any[] = [
    { name: "学科試験", val: 1, isAuth: false },
    { name: "実技試験 金財  個人資産相談業務", val: 2, isAuth: false },
    { name: "実技試験 金財  保険顧客資産相談業務", val: 3, isAuth: false },
    { name: "実技試験 日本FP 協会　資産設計提案業務", val: 4, isAuth: false },
  ]

  private shikens1: any[] = [
    { name: "学科試験", val: 7, isAuth: false },
    { name: "実技試験 金財  個人資産相談業務", val: 8, isAuth: false },
    { name: "実技試験 金財  保険顧客資産相談業務", val: 9, isAuth: false },
    { name: "実技試験 日本FP 協会　資産設計提案業務", val: 10, isAuth: false },
  ]

  private shikens2: any[] = [
    { name: "学科試験", val: 11, isAuth: false },
    { name: "実技試験 金財  個人資産相談業務", val: 12, isAuth: false },
    { name: "実技試験 金財  保険顧客資産相談業務", val: 13, isAuth: false },
    { name: "実技試験 日本FP 協会　資産設計提案業務", val: 14, isAuth: false },
  ]

  private shikens3: any[] = [
    { name: "学科試験", val: 15, isAuth: false },
    { name: "実技試験 金財  個人資産相談業務", val: 16, isAuth: false },
    { name: "実技試験 金財  保険顧客資産相談業務", val: 17, isAuth: false },
    { name: "実技試験 日本FP 協会　資産設計提案業務", val: 18, isAuth: false },
  ]


  created() {

    document.getElementById("body")!.classList.add("auth-body");
    this.gradeStr = "2級";
    this.$nextTick(function () {
      // this.qas = BasicUtil.getQa3(this.currentBook);
      this.setAuth()
    });
  }

  setAuth() {
    this.shikens.forEach((s) => {
      s.isAuth = BasicUtil.isAuth(s.val)
    })

    this.shikens1.forEach((s) => {
      s.isAuth = BasicUtil.isAuth(s.val)
    })

    this.shikens2.forEach((s) => {
      s.isAuth = BasicUtil.isAuth(s.val)
    })

    this.shikens3.forEach((s) => {
      s.isAuth = BasicUtil.isAuth(s.val)
    })

    let ids = [1,2,3,4]
    let ids2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
    this.isAllAuth = true
    ids.forEach(n => {
      if (!BasicUtil.isAuth(n)) {
        this.isAllAuth = false
        return
      }
    })

    this.isAllAuth2 = true
    ids2.forEach(n => {
      if (!BasicUtil.isAuth(n)) {
        this.isAllAuth2 = false
        return
      }
    })
  }

  selectedBook() {
    this.answer = ""
    
    switch (Number(this.currentBook)) {
      case 1:
        this.currentShikenIds = [1,2,3,4]
        break
      case 2:
        this.currentShikenIds = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
        break
    }
    this.$nextTick(function () {
      this.qas = BasicUtil.getQa3(this.currentBook);
    });
  }
  private answerQa() {
    this.isError = false
    if (BasicUtil.zentoHanNumber(this.answer) != this.qas.a) {

      this.isError = true
      return;
    }
    this.currentShikenIds.forEach((si) => {
      BasicUtil.setGradePass(si);
    })

    this.setAuth()
    this.currentBook = 0
    this.currentShikenIds = []
    this.qas = {q: "", a: ""}
  }
}
</script>

<style scoped>
.q-list-text {
  font-weight: bold;
  font-size: 20px;
  flex: 1;
}

.disa {
  opacity: 0.7;
}

.q-list {
  display: flex;
  /* Flexコンテナとして指定 */
  align-items: center;
  /* 縦方向の中心に配置 */
  justify-content: center;
}

.question-wrap {
  background: #fff;
  border-radius: 0.7rem;
  padding: 1.5rem;
  -moz-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, .2));
  -ms-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, .2));
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, .2));
  margin-bottom: 1.5em;
}

/*--------------------------------------------------------------
 ## base
 --------------------------------------------------------------*/

#wrap {
  overflow-x: hidden;
  /* ios-fixed */
  padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}



/* text */
.fzXs2 {
  font-size: 1.1rem;
}

.fzXs {
  font-size: 1.2rem;
}

.fzS {
  font-size: 1.4rem;
}

.fzM {
  font-size: 1.6rem;
}

.fzL {
  font-size: 1.8rem;
}

.fzXl {
  font-size: 2.0rem;
}

.fzXl2 {
  font-size: 2.4rem;
}

.fzXl3 {
  font-size: 3.2rem;
}

.fzXl4 {
  font-size: 4.0rem;
}

@media screen and (min-width: 768px) {
  .fzXs2 {
    font-size: 1.1rem;
  }

  .fzXs {
    font-size: 1.2rem;
  }

  .fzS {
    font-size: 1.4rem;
  }

  .fzM {
    font-size: 1.6rem;
  }

  .fzL {
    font-size: 1.8rem;
  }

  .fzXl {
    font-size: 2.0rem;
  }

  .fzXl2 {
    font-size: 2.4rem;
  }

  .fzXl3 {
    font-size: 3.2rem;
  }

  .fzXl4 {
    font-size: 4.0rem;
  }
}

.bB {
  font-weight: bold;
}

.bN {
  font-weight: normal;
}

.tUl {
  text-decoration: underline;
}




.tWht {
  color: #FFF;
}

.tGry {
  color: #C8C8C8;
}

.tGry2 {
  color: #F8F8F8;
}

.tBlk {
  color: #010101;
}

.tBlk2 {
  color: #323232;
}

.tBlu {
  color: #3596da;
}

.tBlu2 {
  color: #0065ac;
}

.tBlu3 {
  color: #004778;
}

.tBlu4 {
  color: #e4eef4;
}

.tBlu5 {
  color: #e9f3f9;
}

.tBlu6 {
  color: #8cc2e7;
}

.tBlu7 {
  color: #cdeaff;
}

.tGrn {
  color: #01b2a0;
}

.tGrn2 {
  color: #008d7f;
}

.tGrn3 {
  color: #00584f;
}

.tGrn4 {
  color: #008376;
}

.tGrn5 {
  color: #7fbe25;
}

.tGrn6 {
  color: #9cdfd8;
}

.tGrn7 {
  color: #008376;
}

.tOrg {
  color: #f08221;
}

.tPnk {
  color: #ec6e94;
}

.tYel {
  color: #ffff00;
}

.tYel2 {
  color: #fff353;
}

.tYel3 {
  color: #c39815;
}

.tRed {
  color: #dd0000;
}



/* background */
.bgWht {
  background-color: #FFF;
}

.bgGry {
  background-color: #C8C8C8;
}

.bgGry2 {
  background-color: #F8F8F8;
}

.bgBlk {
  background-color: #010101;
}

.bgBlk2 {
  background-color: #323232;
}

.bgBlu {
  background-color: #3596da;
}

.bgBlu2 {
  background-color: #0065ac;
}

.bgBlu3 {
  background-color: #004778;
}

.bgBlu4 {
  background-color: #e4eef4;
}

.bgBlu5 {
  background-color: #e9f3f9;
}

.bgBlu6 {
  background-color: #8cc2e7;
}

.bgBlu7 {
  background-color: #cdeaff;
}

.bgGrn {
  background-color: #01b2a0;
}

.bgGrn2 {
  background-color: #008d7f;
}

.bgGrn3 {
  background-color: #00584f;
}

.bgGrn4 {
  background-color: #008376;
}

.bgGrn5 {
  background-color: #7fbe25;
}

.bgGrn6 {
  background-color: #9cdfd8;
}

.bgGrn7 {
  background-color: #008376;
}

.bgOrg {
  background-color: #f08221;
}

.bgPnk {
  background-color: #ec6e94;
}

.bgYel {
  background-color: #ffff00;
}

.bgYel2 {
  background-color: #fff353;
}

.bgYel3 {
  background-color: #c39815;
}

.bgGryGd {
  background: transparent linear-gradient(180deg, #f8f8f8 0%, #d0d0d0 100%) 0% 0% no-repeat;
}

.bgYelGd {
  background: transparent linear-gradient(180deg, #fff600 0%, #ebb100 100%) 0% 0% no-repeat;
}

/* .bgBluCheck {
   background-repeat: repeat;
   background-image: url('../img/bg_blu_check.jpg');
 }
 .bgGryCheck {
   background-repeat: repeat;
   background-image: url('../img/bg_gry_check.jpg');
 } */

.bgNone {
  background: none;
}


.sdwBlu {
  box-shadow: 0px 4px 0px 0px #8cc2e7;
}

.sdwBlu2 {
  box-shadow: 0px 4px 0px 0px #165f92;
}

.sdwYel {
  box-shadow: 0px 4px 0px 0px #c39815;
}

.sdwGrn {
  box-shadow: 0px 4px 0px 0px #9cdfd8;
}

.sdwGrn2 {
  box-shadow: 0px 4px 0px 0px #008376;
}

.sdwGry {
  box-shadow: 0px 4px 0px 0px #999;
}



.borderBlk {
  border-color: #010101 !important;
}

.borderWht {
  border-color: #fff !important;
}

.borderBlu {
  border-color: #3596da !important;
}

.borderOrg {
  border-color: #f08221 !important;
}

.borderGrn {
  border-color: #01b2a0 !important;
}

.borderGrn5 {
  border-color: #7fbe25 !important;
}

.borderPnk {
  border-color: #ec6e94 !important;
}

.borderNone {
  border: none !important;
}


.altFont {
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

.altFont.bB {
  font-weight: 800 !important;
}

.altFont2 {
  font-family: 'Ropa Sans', sans-serif;
}


/* link/btn */
a {
  color: #010101;
  transition: none;
}

a:hover,
a:focus {
  color: #010101;
  text-decoration: none;
  opacity: 0.8;
}

a.tWht {
  color: #fff;
}

a.tWht:hover,
a.tWht:focus {
  color: #fff;
}

a.tBlk {
  color: #010101;
}

a.tBlk:hover,
a.tBlk:focus {
  color: #010101;
}





.btn {
  border: none;
  transition: all 300ms ease-out;
}

.btn:hover {
  opacity: 0.9;
}

.btn:focus {
  outline: 0;
}


.cur {
  cursor: pointer;
}

.cur:hover {
  opacity: 0.9;
}


/* browser-fix */
*:focus {
  outline: none;
  box-shadow: none;
}






img {
  max-width: 100%;
  height: auto;
}

a img:hover {
  opacity: 0.8;
  transition: all .3s;
}

.bnr {
  max-width: 100%;
  height: auto;
  /* cursor: pointer */
}




/* figure */
figure {
  margin: 0;
  padding: 0;
  line-height: 1em;
}

/* ol/ul */
ol,
ul {
  list-style: none;
  /* line-height: 0; */
}

li {
  /* line-height: 100%; */
}

/* p */
p {
  margin-top: 0;
  margin-bottom: 0;
}

/* h */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
  margin: initial;
}

/* hr */
hr {
  border-top: 1px solid rgba(0, 0, 0, 1);
}


/* width height; */
.w10p {
  width: 10%;
  margin-left: auto;
  margin-right: auto;
}

.w20p {
  width: 20%;
  margin-left: auto;
  margin-right: auto;
}

.w30p {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.w40p {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.w50p {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.w60p {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.w70p {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.w80p {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.w90p {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.w100p {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.w100v {
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.h50p {
  height: 50%;
}

.h100p {
  height: 100%;
}

.h100v {
  height: 100vh;
}


/* border-radius */
.bdr0 {
  border-radius: 0px;
}

.bdr5 {
  border-radius: 5px;
}

.bdr10 {
  border-radius: 10px;
}

.bdr20 {
  border-radius: 20px;
}

.bdr30 {
  border-radius: 30px;
}

.bdr50 {
  border-radius: 50px;
}

.bdr50r {
  border-radius: 50%;
}


/* float */
.fLeft {
  float: left;
}

.fRight {
  float: right;
}

.fNon {
  float: none;
  margin: auto;
}


/* text-align */
.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}


/* display */
.d-flex {
  flex-direction: row;
  flex-wrap: wrap;
}

.jFlexS {
  justify-content: flex-start;
}

.jFlexC {
  justify-content: center;
}

.jFlexE {
  justify-content: flex-end;
}

.jFlexAr {
  justify-content: space-around;
}

.jFlexEv {
  justify-content: space-evenly;
}

.jFlexBt {
  justify-content: space-between;
}

.jFlexSt {
  justify-content: stretch;
}

.aFlexS {
  align-items: flex-start;
}

.aFlexC {
  align-items: center;
}

.aFlexE {
  align-items: flex-end;
}

.aFlexAr {
  align-items: space-around;
}

.aFlexEv {
  align-items: space-evenly;
}

.aFlexBt {
  align-items: space-between;
}

.aFlexSt {
  align-items: stretch;
}

/* vertical-align */
.vTop {
  vertical-align: top;
  display: inline;
}

.vMdl {
  vertical-align: middle;
  display: inline;
}

.vBtm {
  vertical-align: bottom;
  display: inline;
}


/* z-index */
.zIdx-1 {
  z-index: -1;
}

.zIdx0 {
  z-index: 0;
}

.zIdx1 {
  z-index: 1;
}

.zIdx2 {
  z-index: 2;
}

.zIdx3 {
  z-index: 3;
}






/* bootstrap-fix */

.badge {
  display: inline-block;
  padding: .5rem 1.5rem;
  font-size: inherit;
  border-radius: 5px;
}

/* 5 Columns */
.col-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-15 {
  max-width: 20%;
  flex: 0 0 20%;
}



/* dropdown-menu */
.dropdown-menu {
  margin: 0;
}



/* form */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  font-size: 1.4rem;
  line-height: 1.4em;
  height: 3em;
  border-radius: 0;
  /* background-color: #fff; */
  /* border: none; */
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
  color: #010101;
  background-color: #fff;
  border-color: #3596da;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input.iconfont {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-size: inherit;
  text-decoration: inherit;
}

.formZone {}

.form-control {
  font-size: 1.4rem;
}


/* wpcf7-fix */
div.wpcf7 .ajax-loader {
  display: block;
  margin: 10px auto;
}


/* wp-fix */
.wpFixP p {
  padding: 0;
  margin-bottom: 0;
}


.download_list_block {
  /* height: 110px;
     overflow: scroll; */
}

.download_list_block ul {
  list-style: none;
  line-height: 60px;
  padding: 0px;
}

.download_list_block ul li {
  border-bottom: dotted 1px #e7e6eb;
  margin: 3px 0;
}

.download_list_block ul li a {
  cursor: pointer;
}

.download_list_block ul li .date {
  display: inline-block;
  width: 115px;
  padding: 0 10px 0 0;
  line-height: 20px;
}

.download_list_block ul li .download_link {
  display: inline-block;
  padding-right: 20px;
  color: #0065AC;
  line-height: 40px;
  font-weight: 600;
}

.download_list_block ul li .download_link:hover {
  color: #2e75b6;
}

@media only screen and (max-width: 450px) {
  .download_list_block ul li .download_link:after {
    width: 7px;
    height: 13px;
  }
}




/* text */
.fzXs2 {
  font-size: 1.1rem;
}

.fzXs {
  font-size: 1.2rem;
}

.fzS {
  font-size: 1.4rem;
}

.fzM {
  font-size: 1.6rem;
}

.fzL {
  font-size: 1.8rem;
}

.fzXl {
  font-size: 2.0rem;
}

.fzXl2 {
  font-size: 2.4rem;
}

.fzXl3 {
  font-size: 3.2rem;
}

.fzXl4 {
  font-size: 4.0rem;
}

@media screen and (min-width: 768px) {
  .fzXs2 {
    font-size: 1.1rem;
  }

  .fzXs {
    font-size: 1.2rem;
  }

  .fzS {
    font-size: 1.4rem;
  }

  .fzM {
    font-size: 1.6rem;
  }

  .fzL {
    font-size: 1.8rem;
  }

  .fzXl {
    font-size: 2.0rem;
  }

  .fzXl2 {
    font-size: 2.4rem;
  }

  .fzXl3 {
    font-size: 3.2rem;
  }

  .fzXl4 {
    font-size: 4.0rem;
  }
}

.bB {
  font-weight: bold;
}

.bN {
  font-weight: normal;
}

.tUl {
  text-decoration: underline;
}




.tWht {
  color: #FFF;
}

.tGry {
  color: #C8C8C8;
}

.tGry2 {
  color: #F8F8F8;
}

.tBlk {
  color: #010101;
}

.tBlk2 {
  color: #323232;
}

.tBlu {
  color: #3596da;
}

.tBlu2 {
  color: #0065ac;
}

.tBlu3 {
  color: #004778;
}

.tBlu4 {
  color: #e4eef4;
}

.tBlu5 {
  color: #e9f3f9;
}

.tBlu6 {
  color: #8cc2e7;
}

.tBlu7 {
  color: #cdeaff;
}

.tGrn {
  color: #01b2a0;
}

.tGrn2 {
  color: #008d7f;
}

.tGrn3 {
  color: #00584f;
}

.tGrn4 {
  color: #008376;
}

.tGrn5 {
  color: #7fbe25;
}

.tGrn6 {
  color: #9cdfd8;
}

.tGrn7 {
  color: #008376;
}

.tOrg {
  color: #f08221;
}

.tPnk {
  color: #ec6e94;
}

.tYel {
  color: #ffff00;
}

.tYel2 {
  color: #fff353;
}

.tYel3 {
  color: #c39815;
}

.tRed {
  color: #dd0000;
}



/* background */
.bgWht {
  background-color: #FFF;
}

.bgGry {
  background-color: #C8C8C8;
}

.bgGry2 {
  background-color: #F8F8F8;
}

.bgBlk {
  background-color: #010101;
}

.bgBlk2 {
  background-color: #323232;
}

.bgBlu {
  background-color: #3596da;
}

.bgBlu2 {
  background-color: #0065ac;
}

.bgBlu3 {
  background-color: #004778;
}

.bgBlu4 {
  background-color: #e4eef4;
}

.bgBlu5 {
  background-color: #e9f3f9;
}

.bgBlu6 {
  background-color: #8cc2e7;
}

.bgBlu7 {
  background-color: #cdeaff;
}

.bgGrn {
  background-color: #01b2a0;
}

.bgGrn2 {
  background-color: #008d7f;
}

.bgGrn3 {
  background-color: #00584f;
}

.bgGrn4 {
  background-color: #008376;
}

.bgGrn5 {
  background-color: #7fbe25;
}

.bgGrn6 {
  background-color: #9cdfd8;
}

.bgGrn7 {
  background-color: #008376;
}

.bgOrg {
  background-color: #f08221;
}

.bgPnk {
  background-color: #ec6e94;
}

.bgYel {
  background-color: #ffff00;
}

.bgYel2 {
  background-color: #fff353;
}

.bgYel3 {
  background-color: #c39815;
}

.bgGryGd {
  background: transparent linear-gradient(180deg, #f8f8f8 0%, #d0d0d0 100%) 0% 0% no-repeat;
}

.bgYelGd {
  background: transparent linear-gradient(180deg, #fff600 0%, #ebb100 100%) 0% 0% no-repeat;
}

/* .bgBluCheck {
   background-repeat: repeat;
   background-image: url('../img/bg_blu_check.jpg');
 }
 .bgGryCheck {
   background-repeat: repeat;
   background-image: url('../img/bg_gry_check.jpg');
 } */

.bgNone {
  background: none;
}


.sdwBlu {
  box-shadow: 0px 4px 0px 0px #8cc2e7;
}

.sdwBlu2 {
  box-shadow: 0px 4px 0px 0px #165f92;
}

.sdwYel {
  box-shadow: 0px 4px 0px 0px #c39815;
}

.sdwGrn {
  box-shadow: 0px 4px 0px 0px #9cdfd8;
}

.sdwGrn2 {
  box-shadow: 0px 4px 0px 0px #008376;
}



.borderBlk {
  border-color: #010101 !important;
}

.borderWht {
  border-color: #fff !important;
}

.borderBlu {
  border-color: #3596da !important;
}

.borderOrg {
  border-color: #f08221 !important;
}

.borderGrn {
  border-color: #01b2a0 !important;
}

.borderGrn5 {
  border-color: #7fbe25 !important;
}

.borderPnk {
  border-color: #ec6e94 !important;
}

.borderNone {
  border: none !important;
}


.altFont {
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

.altFont.bB {
  font-weight: 800 !important;
}

.altFont2 {
  font-family: 'Ropa Sans', sans-serif;
}


/* link/btn */
a {
  color: #010101;
  transition: none;
}

a:hover,
a:focus {
  color: #010101;
  text-decoration: none;
  opacity: 0.8;
}

a.tWht {
  color: #fff;
}

a.tWht:hover,
a.tWht:focus {
  color: #fff;
}

a.tBlk {
  color: #010101;
}

a.tBlk:hover,
a.tBlk:focus {
  color: #010101;
}





.btn {
  border: none;
  transition: all 300ms ease-out;
}

.btn:hover {
  opacity: 0.9;
  background-color: #008376 !important;
}

.btn:focus {
  outline: 0;
}


.cur {
  cursor: pointer;
}

.cur:hover {
  opacity: 0.9;
}


/* browser-fix */
*:focus {
  outline: none;
  box-shadow: none;
}






img {
  max-width: 100%;
  height: auto;
}

a img:hover {
  opacity: 0.8;
  transition: all .3s;
}

.bnr {
  max-width: 100%;
  height: auto;
  /* cursor: pointer */
}




/* figure */
figure {
  margin: 0;
  padding: 0;
  line-height: 1em;
}

/* ol/ul */
ol,
ul {
  list-style: none;
  /* line-height: 0; */
}

li {
  /* line-height: 100%; */
}

/* p */
p {
  margin-top: 0;
  margin-bottom: 0;
}

/* h */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
  margin: initial;
}

/* hr */
hr {
  border-top: 1px solid rgba(0, 0, 0, 1);
}


/* width height; */
.w10p {
  width: 10%;
  margin-left: auto;
  margin-right: auto;
}

.w20p {
  width: 20%;
  margin-left: auto;
  margin-right: auto;
}

.w30p {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.w40p {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.w50p {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.w60p {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.w70p {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.w80p {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.w90p {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.w100p {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.w100v {
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.h50p {
  height: 50%;
}

.h100p {
  height: 100%;
}

.h100v {
  height: 100vh;
}


/* border-radius */
.bdr0 {
  border-radius: 0px;
}

.bdr5 {
  border-radius: 5px;
}

.bdr10 {
  border-radius: 10px;
}

.bdr20 {
  border-radius: 20px;
}

.bdr30 {
  border-radius: 30px;
}

.bdr50 {
  border-radius: 50px;
}

.bdr50r {
  border-radius: 50%;
}


/* float */
.fLeft {
  float: left;
}

.fRight {
  float: right;
}

.fNon {
  float: none;
  margin: auto;
}


/* text-align */
.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}


/* display */
.d-flex {
  flex-direction: row;
  flex-wrap: wrap;
}

.jFlexS {
  justify-content: flex-start;
}

.jFlexC {
  justify-content: center;
}

.jFlexE {
  justify-content: flex-end;
}

.jFlexAr {
  justify-content: space-around;
}

.jFlexEv {
  justify-content: space-evenly;
}

.jFlexBt {
  justify-content: space-between;
}

.jFlexSt {
  justify-content: stretch;
}

.aFlexS {
  align-items: flex-start;
}

.aFlexC {
  align-items: center;
}

.aFlexE {
  align-items: flex-end;
}

.aFlexAr {
  align-items: space-around;
}

.aFlexEv {
  align-items: space-evenly;
}

.aFlexBt {
  align-items: space-between;
}

.aFlexSt {
  align-items: stretch;
}

/* vertical-align */
.vTop {
  vertical-align: top;
  display: inline;
}

.vMdl {
  vertical-align: middle;
  display: inline;
}

.vBtm {
  vertical-align: bottom;
  display: inline;
}


/* z-index */
.zIdx-1 {
  z-index: -1;
}

.zIdx0 {
  z-index: 0;
}

.zIdx1 {
  z-index: 1;
}

.zIdx2 {
  z-index: 2;
}

.zIdx3 {
  z-index: 3;
}






/* bootstrap-fix */

.badge {
  display: inline-block;
  padding: .5rem 1.5rem;
  font-size: inherit;
  border-radius: 5px;
}

/* 5 Columns */
.col-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-15 {
  max-width: 20%;
  flex: 0 0 20%;
}



/* dropdown-menu */
.dropdown-menu {
  margin: 0;
}



/* form */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  font-size: 1.4rem;
  line-height: 1.4em;
  height: 3em;
  border-radius: 0;
  /* background-color: #fff; */
  /* border: none; */
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
  color: #010101;
  background-color: #fff;
  border-color: #3596da;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input.iconfont {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-size: inherit;
  text-decoration: inherit;
}

.formZone {}

.form-control {
  font-size: 1.4rem;
}


/* wpcf7-fix */
div.wpcf7 .ajax-loader {
  display: block;
  margin: 10px auto;
}


/* wp-fix */
.wpFixP p {
  padding: 0;
  margin-bottom: 0;
}


.download_list_block {
  /* height: 110px;
     overflow: scroll; */
}

.download_list_block ul {
  list-style: none;
  line-height: 60px;
  padding: 0px;
}

.download_list_block ul li {
  border-bottom: dotted 1px #e7e6eb;
  margin: 3px 0;
}

.download_list_block ul li a {
  cursor: pointer;
}

.download_list_block ul li .date {
  display: inline-block;
  width: 115px;
  padding: 0 10px 0 0;
  line-height: 20px;
}

.download_list_block ul li .download_link {
  display: inline-block;
  padding-right: 20px;
  color: #0065AC;
  line-height: 40px;
  font-weight: 600;
}

.download_list_block ul li .download_link:hover {
  color: #2e75b6;
}

@media only screen and (max-width: 450px) {
  .download_list_block ul li .download_link:after {
    width: 7px;
    height: 13px;
  }
}
</style>